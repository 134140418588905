import * as R from 'ramda'
import { graphql } from 'gatsby'
import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core'

import algoliasearch from 'algoliasearch/lite'
import {
  Configure,
  CurrentRefinements,
  Highlight,
  InstantSearch,
  connectInfiniteHits,
  connectSearchBox,
  connectSortBy,
  connectToggleRefinement,
  connectRefinementList,
  RefinementList
} from 'react-instantsearch-dom'

import SEO from '../components/SEO'
import Hero from '../components/Hero'
import Layout from '../components/Layout'

import { mapIndexed, notNilOrEmpty } from '../lib/Helpers'

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const names = [
  {
    name: 'Small Business Phase 0/1',
    value: 'phase_0'
  },
  {
    name: 'Small Business Phase 2+',
    value: 'phase_2'
  },
  {
    name: 'Research Institution',
    value: 'research'
  },
  {
    name: 'Large Business/Investor',
    value: 'investor'
  },
  {
    name: 'Government',
    value: 'government'
  },
  {
    name: 'Other',
    value: 'other'
  }
]

export default props => {
  const {
    data: { sanityPageContent: pageContent }
  } = props

  const Hit = ({ hits, hasPrevious, refinePrevious, hasMore, refineNext }) => {
    return (
      <>
        <section className="business-cards">
          {mapIndexed((hit, index) => (
            <div className="business-cards__single animated" key={index}>
              <div className="filtered-colors">
                <div className={`filtered-colors__${hit.phase}`} />
              </div>
              <div className="company_image">
                {notNilOrEmpty(hit.logo) && (
                  <img src={hit.logo} alt={hit.title} />
                )}
              </div>
              <div className="company_data">
                <p className="company_data__title">
                  <Highlight attribute="title" hit={hit} />
                </p>
                <p className="company_data__desc">
                  <p className="company_data__contact">
                    <small>{hit.company_primary_contact}</small>
                  </p>
                  <p className="company_data__email">
                    <small>
                      Email:{' '}
                      <a href={`mailto:${hit.company_primary_contact_email}`}>
                        {hit.company_primary_contact_email}
                      </a>
                    </small>
                  </p>
                  <p className="company_data__phone">
                    <small>{hit.company_phone}</small>
                  </p>
                  <p className="company_data__url">
                    <small>
                      <a
                        href={hit.company_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {hit.company_url}
                      </a>
                    </small>
                  </p>
                  <Highlight attribute="description" hit={hit} />
                </p>
              </div>
            </div>
          ))(hits)}
        </section>
        {hasMore && (
          <div className="show-more-btn-container">
            <button className="btn btn--yellow" onClick={refineNext}>
              Show more
            </button>
          </div>
        )}
      </>
    )
  }

  const SearchBox = ({ currentRefinement, refine }) => (
    <TextField
      type="search"
      label="Search"
      variant="outlined"
      className="search"
      value={currentRefinement}
      onChange={event => refine(event.currentTarget.value)}
    />
  )

  const SortBy = props => {
    const { items, refine } = props
    return (
      <FormControl variant="outlined" className="filter-dropdown sort">
        <InputLabel id="sort-label">Sort</InputLabel>
        <Select
          label="Sort"
          labelId="sort-label"
          id="sort-select"
          defaultValue={props.defaultSort.value}
          onChange={e => refine(e.target.value)}
        >
          {mapIndexed((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          ))(items)}
        </Select>
      </FormControl>
    )
  }

  const MultiSelect = props => {
    const [checked, setChecked] = React.useState(false)
    const handleChange = (event, item) => {
      refine(item.value)
      setChecked(!checked)
    }
    const { items, createURL, currentRefinement, label, refine } = props

    return names.map(item => (
      <FormControlLabel
        classes={{ root: item.value }}
        key={item.value}
        label={item.name}
        control={
          <Checkbox
            checked={R.contains(item.value, currentRefinement) ? true : false}
            onChange={event => {
              event.preventDefault()
              handleChange(event, R.find(R.propEq('label', item.value))(items))
            }}
            name={item.value}
          />
        }
      />
    ))
  }

  const MultiSelectOptions = connectRefinementList(MultiSelect)
  const CustomSearchBox = connectSearchBox(SearchBox)
  const BusinessCardHit = connectInfiniteHits(Hit)
  const CustomSortBy = connectSortBy(SortBy)

  return (
    <Layout>
      <SEO
        title={`${pageContent.title}`}
        keywords={pageContent.pageSeo.keywords}
        description={pageContent.description}
        author={pageContent.pageSeo.author}
        image={pageContent.pageSeo.seo_image.asset.url}
        url={`https://nasa.collaboration.ai`}
      />
      <Hero
        title=""
        className=""
        overlayImg={
          pageContent.hero_overlay_image &&
          pageContent.hero_overlay_image.asset.fluid.src
        }
        img={
          notNilOrEmpty(pageContent.hero_image) &&
          pageContent.hero_image.asset.url
        }
      />
      <div className="container">
        <InstantSearch
          searchClient={searchClient}
          indexName="NetworkingDirectory"
        >
          <Configure
            distinct
            attributesToSnippet={['description:10']}
            snippetEllipsisText="…"
            removeWordsIfNoResults="allOptional"
            hitsPerPage={21}
          />
          <div className="filters filters--multiple-checkboxes">
            <MultiSelectOptions attribute="phase" />
          </div>
          <section className="filters filters--business-cards">
            <CustomSortBy
              defaultRefinement="NetworkingDirectory"
              currentRefinement=""
              defaultSort={{ value: 'NetworkingDirectory', label: 'A - Z' }}
              items={[
                { value: 'NetworkingDirectory', label: 'A - Z' },
                { value: 'NetworkingDirectory_DESC', label: 'Z - A' }
              ]}
            />
            <CustomSearchBox />
          </section>
          <div
            className=""
            style={{ margin: '0 auto 50px', textAlign: 'center' }}
          >
            <AniLink
              cover
              to="/manage-networking-directory"
              duration={1.25}
              direction="right"
              className="btn btn--black"
              bg={`
                url(${pageContent.logo.asset.fluid.src})
                center / 50% /* position / size */
                no-repeat        /* repeat */
                fixed            /* attachment */
                padding-box      /* origin */
                content-box      /* clip */
                #00021a          /* color */
              `}
            >
              Add your information here
            </AniLink>
          </div>
          <BusinessCardHit />
        </InstantSearch>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    sanityPageContent {
      _rawBodyContent
      title
      hero_bg_video_url
      hero_overlay_image {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      hero_image {
        asset {
          url
        }
      }
      bodyContent_separator_img {
        asset {
          fixed(width: 400) {
            ...GatsbySanityImageFixed
          }
        }
      }
      logo {
        asset {
          fluid(maxHeight: 700) {
            ...GatsbySanityImageFluid
          }
        }
      }
      page_transition_logo {
        asset {
          url
        }
      }
      pageSeo {
        author
        description
        keywords
        seo_image {
          asset {
            url
          }
        }
      }
    }
  }
`
